import { render, staticRenderFns } from "./FinancePersonTableSimple.vue?vue&type=template&id=b3c3c9d0"
import script from "./FinancePersonTableSimple.vue?vue&type=script&lang=js"
export * from "./FinancePersonTableSimple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports