<template>
  <span v-if="value && value.length > 0">{{ formated }}</span>
  <span v-else>{{ placeholder }}</span>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan, formatDate } from "common/utils/date";
export default defineComponent({
  name: "DateSpan",
  props: {
    value: { type: Array },
    placeholder: { type: String, default: "—" },
    long: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
  },
  computed: {
    sorted() {
      return this.value.sort((a, b) => a.localeCompare(b));
    },
    startDate() {
      if (this.sorted.length > 0) {
        return this.sorted[0];
      }
      return null;
    },
    endDate() {
      if (this.sorted.length > 1) {
        return this.sorted[this.sorted.length - 1];
      }
      return null;
    },
    formated() {
      if (this.value.length == 1) {
        return formatDate(this.value[0], this.long, this.full);
      }
      if (this.sorted.length > 1) {
        return formatDatespan(
          this.startDate,
          this.endDate,
          this.long,
          this.full
        );
      }
      return this.placeholder;
    },
  },
});
</script>
